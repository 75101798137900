<template>
  <v-dialog v-model="dialog" max-width="800px" persistent>
    <v-card>
      <v-card-title>
        <span color="primary" class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" :valid="valid">
          <v-row>
            <v-col md="4">
              <v-text-field
                placeholder="المحور"
                label="المحور"
                v-model="sectionForm.title"
                :rules="[(v) => !!v || 'title is required']"
              />
            </v-col>
            <v-col md="4">
              <v-text-field
                placeholder="section"
                label="section"
                v-model="sectionForm.entitle"
                :rules="[(v) => !!v || 'title is required']"
              />
            </v-col>
            <v-col md="4">
              <v-text-field
                placeholder="المدة"
                label="المدة"
                v-model="sectionForm.duration"
                :rules="[(v) => !!v || 'duration is required']"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="close">
          إلغاء
        </v-btn>
        <v-btn color="blue darken-1" text @click="submit">
          إرسال
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="submitted">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <!-- snackbar -->
    <v-snackbar v-model="snackbar" absolute top :multi-line="true">
      {{ error }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import ApiService from "@/services/api.service";
// import { mapActions } from "vuex";

export default {
  props: ["formTitle", "record", "dialog", "id"],
  data() {
    return {
      sectionForm: {
        id: null,
        title: null,
        entitle: null,
        duration: null,
      },
      snackbar: false,
      valid: false,
      submitted: false,
      loading: false,
      error: "",

      textRules: [(v) => !!v || "field is required"],

      // =================== for updating users ===================
      initialPayload: {},
    };
  },
  methods: {
    async updateRecord() {
      try {
        const payloadInfoChanged = {};
        Object.keys(this.sectionForm).forEach((key) => {
          if (Array.isArray(this.sectionForm[key])) {
            const isEqual = this.sectionForm[key].every((field) => {
              return this.initialPayload[key].includes(field);
            });

            if (!isEqual) payloadInfoChanged[key] = this.sectionForm[key];
          } else if (
            typeof this.sectionForm[key] === "object" &&
            this.sectionForm[key] !== null
          ) {
            Object.keys(this.sectionForm[key]).forEach((key2) => {
              if (
                this.initialPayload[key][key2] !== this.sectionForm[key][key2]
              ) {
                payloadInfoChanged[key][key2] = this.sectionForm[key][key2];
              }
            });
          } else if (this.initialPayload[key] !== this.sectionForm[key]) {
            // this.initialPayload[key] !== this.sectionForm[key];
            payloadInfoChanged[key] = this.sectionForm[key];
          }
        });

        // send requsts
        if (Object.keys(payloadInfoChanged).length > 0) {
          // send request ...
        }
      } catch (error) {
        console.log(error);
      }
    },
    async submit() {
      try {
        if (this.$refs.form.validate()) {
          this.submitted = true;
          if (this.record) {
            const sectionForm = await ApiService.update(
              "items/sections",
              this.sectionForm.id,
              this.sectionForm
            );
          } else {
            this.sectionForm["course_id"] = this.id;
            // console.log(this.sectionForm);
            const sectionReq = await ApiService.post(
              "items/sections",
              this.sectionForm
            );
          }
          this.submitted = false;
          const msg = this.record
            ? "record updated successfully"
            : "record inserted successfully";
          this.$emit("formSubmitted", msg);
        } else {
          this.snackbar = true;
          this.error = "please fill all required fields";
        }
      } catch (error) {
        if (error.message !== "") {
          this.error = error;
        } else {
          this.error = "sorry, something went wrong";
        }
        this.snackbar = true;
        console.log(error);
        this.submitted = false;
      }
    },
    close() {
      this.$emit("closeDialog");
    },
  },

  mounted() {
    this.record && Object.assign(this.sectionForm, this.record);
    Object.assign(this.initialPayload, this.record);
  },
};
</script>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>
