<template>
  <v-form ref="form" :valid="valid">
    <v-row>
      <v-col md="3">
        <v-text-field
          placeholder="الجواب"
          label="الجواب"
          v-model="challengeForm.title"
          :rules="[(v) => !!v || 'title is required']"
        />
      </v-col>
      <v-col md="3">
        <v-text-field
          placeholder="answer"
          label="answer"
          v-model="challengeForm.entitle"
          :rules="[(v) => !!v || 'title is required']"
        />
      </v-col>
      <v-col md="3">
        <v-text-field
          placeholder="الترتيب"
          label="الترتيب"
          v-model="challengeForm.order"
          :rules="[(v) => !!v || 'order is required']"
        />
      </v-col>
      <v-col md="3">
        <v-checkbox
          v-model="challengeForm.is_answer"
          :label="`الجواب الصحيح: ${challengeForm.is_answer.toString()}`"
        ></v-checkbox>
      </v-col>
      <v-col md="12" v-if="challengeForm.is_answer">
        <v-textarea
          v-model="challengeForm.why_is_answer"
          counter
          placeholder="لماذا هو الجواب"
          label="لماذا هو الجواب"
        ></v-textarea>
      </v-col>
      <v-col md="12" v-if="challengeForm.is_answer">
        <v-textarea
          v-model="challengeForm.enwhy_is_answer"
          counter
          placeholder="why is answer"
          label="why is answer"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="10"> </v-col>
      <v-col cols="12" md="2">
        <v-btn color="success" @click="submit">
          إرسال
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-data-table
          :headers="answerHeader"
          :items="answers"
          :items-per-page="5"
          class="elevation-1 "
        >
          <template v-slot:top>
            <v-toolbar flat class="mb-7">
              <!-- delete item -->
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline"
                    >Are you sure you want to delete this user?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="deleteItemConfirm()"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template
            v-slot:item.actions="{ item }"
            style="textAlign:right !important"
          >
            <v-icon
              color="primary"
              small
              class="mr-2"
              @click="editAnswer(item)"
            >
              mdi-pencil
            </v-icon>

            <v-icon
              color="error"
              small
              class="mr-2"
              @click="deleteAnswer(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-overlay :value="submitted">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-form>
</template>

<script>
import ApiService from "@/services/api.service";
// import { mapActions } from "vuex";

export default {
  props: ["formTitle", "dialog", "lecture_id", "parent_id"],
  data() {
    return {
      loadingTypes: false,
      snackbar: false,
      valid: false,
      submitted: false,

      loading: false,
      dialogDelete: false,
      answers: [],
      answerHeader: [
        {
          text: "الجواب",
          value: "title",
        },
        {
          text: "الترتيب",
          value: "order",
        },
        {
          text: "الجواب الصحيح",
          value: "is_answer",
        },
        {
          text: "لماذا الجواب الصحيح",
          value: "why_is_answer",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      error: "",
      challengeForm: {
        id: null,
        title: null,
        entitle: null,
        lecture_id: null,
        parent_id: null,
        is_answer: 0,
        why_is_answer: null,
        enwhy_is_answer: null,
        order: null,
      },
      editorOption: {
        // some quill options
      },
      record: null,
      textRules: [(v) => !!v || "field is required"],

      // =================== for updating users ===================
      initialPayload: {},
    };
  },
  methods: {
    deleteAnswer(record) {
      this.record = record;
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      try {
        this.loading = true;
        await ApiService.delete(`items/challenges/${this.record.id}`);
        this.loadData();
        this.loading = false;
        this.closeDelete();

        alert("Product deleted successfully");
        this.loadData();
      } catch (error) {
        console.log(error);
      }
    },
    closeDelete() {
      this.record = null;
      this.dialogDelete = false;
    },
    editAnswer(item) {
      this.reset();
      Object.keys(this.challengeForm).forEach((element) => {
        if (item[element]) {
          if (typeof item[element] == "object") {
            this.challengeForm[element] = item[element].id;
          } else {
            this.challengeForm[element] = item[element];
          }
        }
      });
    },
    async submit() {
      try {
        if (this.$refs.form.validate()) {
          this.submitted = true;
          if (this.challengeForm.id) {
            const challengeReq = await ApiService.update(
              "items/challenges",
              this.challengeForm.id,
              this.challengeForm
            );
          } else {
            this.challengeForm.lecture_id = this.lecture_id;
            this.challengeForm.parent_id = this.parent_id;
            const challengeReq = await ApiService.post(
              "items/challenges",
              this.challengeForm
            );
          }
          this.submitted = false;
          this.loadData();
          this.reset();
          const msg = this.record
            ? "record updated successfully"
            : "record inserted successfully";

          this.$emit("formSubmitted", this.parent_id, msg);
        } else {
          this.snackbar = true;
          this.error = "please fill all required fields";
        }
      } catch (error) {
        if (error.message !== "") {
          this.error = error;
        } else {
          this.error = "sorry, something went wrong";
        }
        this.snackbar = true;
        console.log(error);
        this.submitted = false;
      }
    },
    reset() {
      this.challengeForm.id = null;
      this.challengeForm.title = null;
      this.challengeForm.entitle = null;
      this.challengeForm.lecture_id = null;
      this.challengeForm.parent_id = null;
      this.challengeForm.is_answer = 0;
      this.challengeForm.why_is_answer = null;
      this.challengeForm.enwhy_is_answer = null;
      this.challengeForm.order = null;
    },
    close() {
      this.$emit("closeDialog");
    },
    async loadData() {
      this.loading = true;
      const challengeReq = await ApiService.get(
        `items/challenges?fields=id,title,is_answer,why_is_answer,entitle,enwhy_is_answer,order,lecture_id,parent_id&filter[parent_id]=${this.parent_id}`
      );
      this.answers = challengeReq.data.data;
    },
  },

  async mounted() {
    // this.record && Object.assign(this.challengeForm, this.record);
    // Object.assign(this.initialPayload, this.record);
    console.log(this.challenge_id);
    console.log(this.parent_id);
  },
};
</script>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>
