<template>
  <v-dialog v-model="dialog" max-width="800px" persistent>
    <v-card>
      <v-card-title>
        <span color="primary" class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" :valid="valid">
          <v-row>
            <v-col md="5">
              <v-text-field
                placeholder="العنوان"
                label="العنوان"
                v-model="lectureForm.title"
                :rules="[(v) => !!v || 'title is required']"
              />
            </v-col>
            <v-col md="5">
              <v-text-field
                placeholder="title"
                label="title"
                v-model="lectureForm.entitle"
                :rules="[(v) => !!v || 'title is required']"
              />
            </v-col>
            <v-col md="2">
              <v-text-field
                placeholder="المدة"
                label="المدة"
                v-model="lectureForm.duration"
                :rules="[(v) => !!v || 'duration is required']"
              />
            </v-col>
            <v-col md="12">
              <quill-editor
                v-model="lectureForm.discription"
                ref="myQuillEditor"
                :options="editorOption"
                :rules="[
                  (v) => !!(v && v.length > 0) || 'please provide body content',
                ]"
              />
            </v-col>
            <v-col md="12">
              <quill-editor
                style="direction:ltr"
                v-model="lectureForm.endiscription"
                ref="myQuillEditor1"
                :options="editorOption"
                :rules="[
                  (v) => !!(v && v.length > 0) || 'please provide body content',
                ]"
              />
            </v-col>
            <v-col md="3">
              <v-file-input
                v-model="attachment"
                chips
                label="مرفق"
              ></v-file-input>
            </v-col>
            <v-col md="3">
              <div v-if="file1" class="image-area" style="margin-bottom: 10px;">
                <img
                  :src="file1.data.full_url"
                  v-if="file1"
                  width="250px"
                  height="250px"
                />
                <!-- @click="openLink(img.data.full_url)" -->

                <a
                  class="remove-image"
                  @click="removeimg(file1.id, 'file1')"
                  href="#"
                  style="display: inline;"
                  >&#215;</a
                >
              </div>
            </v-col>
            <v-col md="6">
              <v-text-field
                placeholder="رابط الفيديو"
                label="رابط الفيديو"
                v-model="lectureForm.video_link"
              />
            </v-col>
            <v-col md="4">
              <v-checkbox
                v-model="lectureForm.is_mandatory"
                :label="`ضروري: ${lectureForm.is_mandatory.toString()}`"
              ></v-checkbox>
            </v-col>
            <v-col md="4">
              <v-checkbox
                v-model="lectureForm.is_visible"
                :label="
                  `الظهور في الإعلان: ${lectureForm.is_visible.toString()}`
                "
              ></v-checkbox>
            </v-col>
            <v-col md="4">
              <v-autocomplete
                :loading="loadingTypes"
                v-model="lectureForm.type"
                :items="types"
                dense
                chips
                small-chips
                label="النوع"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="close">
          إلغاء
        </v-btn>
        <v-btn color="blue darken-1" text @click="submit">
          إرسال
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="submitted">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <!-- snackbar -->
    <v-snackbar v-model="snackbar" absolute top :multi-line="true">
      {{ error }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import ApiService from "@/services/api.service";
// import { mapActions } from "vuex";

export default {
  props: ["formTitle", "record", "dialog", "id"],
  data() {
    return {
      loadingTypes: false,
      snackbar: false,
      valid: false,
      submitted: false,
      loading: false,
      error: "",
      types: ["content", "challenge"],
      lectureForm: {
        id: null,
        title: null,
        discription: null,
        entitle: null,
        endiscription: null,
        type: null,
        video_link: null,
        duration: null,
        is_mandatory: 0,
        is_visible: 0,
        attachment: null,
      },
      editorOption: {
        // some quill options
      },
      attachment: null,
      file1: null,
      textRules: [(v) => !!v || "field is required"],

      // =================== for updating users ===================
      initialPayload: {},
    };
  },
  methods: {
    async removeimg(id, text) {
      const { data } = await ApiService.delete(`files/${id}`);
      this.file1 = null;
      this.lectureForm["attachment"] = null;
      this.submit();
    },
    async updateRecord() {
      try {
        const payloadInfoChanged = {};
        Object.keys(this.lectureForm).forEach((key) => {
          if (Array.isArray(this.lectureForm[key])) {
            const isEqual = this.lectureForm[key].every((field) => {
              return this.initialPayload[key].includes(field);
            });

            if (!isEqual) payloadInfoChanged[key] = this.lectureForm[key];
          } else if (
            typeof this.lectureForm[key] === "object" &&
            this.lectureForm[key] !== null
          ) {
            Object.keys(this.lectureForm[key]).forEach((key2) => {
              if (
                this.initialPayload[key][key2] !== this.lectureForm[key][key2]
              ) {
                payloadInfoChanged[key][key2] = this.lectureForm[key][key2];
              }
            });
          } else if (this.initialPayload[key] !== this.lectureForm[key]) {
            // this.initialPayload[key] !== this.lectureForm[key];
            payloadInfoChanged[key] = this.lectureForm[key];
          }
        });

        // send requsts
        if (Object.keys(payloadInfoChanged).length > 0) {
          // send request ...
        }
      } catch (error) {
        console.log(error);
      }
    },
    async submit() {
      try {
        if (this.$refs.form.validate()) {
          this.submitted = true;
          if (this.attachment) {
            let formData = new FormData();
            formData.append("data", this.attachment);
            const attachmentReq = await ApiService.postFile(
              "files",
              formData,
              0,
              null
            );
            this.lectureForm["attachment"] = attachmentReq.data.data.id;
          }
          if (this.record) {
            const lectureReq = await ApiService.update(
              "items/lectures",
              this.lectureForm.id,
              this.lectureForm
            );
          } else {
            this.lectureForm.section_id = this.id;
            const lectureReq = await ApiService.post(
              "items/lectures",
              this.lectureForm
            );
          }
          this.submitted = false;
          const msg = this.record
            ? "record updated successfully"
            : "record inserted successfully";
          this.$emit("formSubmitted", msg);
        } else {
          this.snackbar = true;
          this.error = "please fill all required fields";
        }
      } catch (error) {
        if (error.message !== "") {
          this.error = error;
        } else {
          this.error = "sorry, something went wrong";
        }
        this.snackbar = true;
        console.log(error);
        this.submitted = false;
      }
    },
    close() {
      this.$emit("closeDialog");
    },
  },

  async mounted() {
    // this.record && Object.assign(this.lectureForm, this.record);
    // Object.assign(this.initialPayload, this.record);
    if (this.record) {
      Object.keys(this.lectureForm).forEach((element) => {
        if (this.record[element]) {
          if (typeof this.record[element] == "object") {
            this.lectureForm[element] = this.record[element].id;
          } else {
            this.lectureForm[element] = this.record[element];
          }
        }
      });
      if (this.lectureForm.attachment) {
        console.log(this.lectureForm.attachment);

        const { data } = await ApiService.getFile(
          "files",
          this.lectureForm.attachment
        );
        this.file1 = data.data;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>
