<template>
  <div>
    <template>
      <v-data-table
        :headers="courseHeaders"
        :items="items"
        item-key="id"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>الدورات</v-toolbar-title>
            <v-spacer></v-spacer>
            <CourseForm
              @closeDialog="openDialog0 = false"
              @formSubmitted="formSubmitted"
              :formTitle="formTitle"
              :dialog="openDialog0"
              :record="record"
              :id="id"
              v-if="openDialog0"
            />
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="dialogOpen(null, 'دورة', null)"
            >
              <v-icon small>mdi-plus</v-icon>
            </v-btn>
            <!-- delete item -->
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline"
                  >Are you sure you want to delete this item?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm()"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template
          v-slot:item.actions="{ item }"
          style="textAlign:right !important"
        >
          <!-- <v-btn to="posts/add" text class="pa-0"> -->
          <v-icon
            color="primary"
            small
            class="mr-2"
            @click="dialogOpen(item, 'دورة', null)"
          >
            mdi-pencil
          </v-icon>
          <!-- </v-btn> -->

          <v-icon color="error" small class="mr-2" @click="deleteItem(item)">
            mdi-delete
          </v-icon>
          <v-icon color="error" small class="mr-2" @click="goToSections(item)">
            <!-- @click="dialogOpen(null, 'section', item.id)"   -->
            mdi-plus
          </v-icon>
        </template>
      </v-data-table>
    </template>
    <v-snackbar
      v-model="snackbar"
      absolute
      top
      :multi-line="true"
      :color="snackbarColor"
    >
      {{ snackbarMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import LectureForm from "./LectureForm";
import SectionForm from "./SectionForm";
import CourseForm from "./CourseForm";
import ChallengeForm from "./ChallengeForm";
import ApiService from "@/services/api.service";

export default {
  components: {
    LectureForm,
    SectionForm,
    CourseForm,
    ChallengeForm,
  },
  data: () => ({
    snackbar: false,
    snackbarMsg: null,
    snackbarColor: "success",
    loadingSection: true,
    id: null,
    openDialog0: false,
    openDialog: false,
    openDialog1: false,
    openDialog2: false,
    dialogDelete: false,
    formTitle: "Add New Item",
    record: null,
    items: [],
    singleExpand: false,
    singleExpand1: false,
    singleExpand2: false,
    singleExpand3: false,
    sectionHeaders: [
      {
        text: "section",
        align: "start",
        sortable: false,
        value: "title",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    lectureHeaders: [
      {
        text: "lecture",
        align: "start",
        sortable: false,
        value: "title",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    challengeHeaders: [
      {
        text: "challenge",
        align: "start",
        sortable: false,
        value: "title",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    courseHeaders: [
      {
        text: "الدورة",
        align: "start",
        sortable: false,
        value: "title",
      },
      { text: "قبل الخصم", value: "before_discount" },
      { text: "بعد الخصم", value: "after_discount" },
      { text: "", value: "actions", sortable: false },
    ],
  }),
  methods: {
    goToSections(item) {
      this.$router.push(`/sections/add/${item.id}`);
    },
    async getCourses() {
      try {
        this.loadingSection = true;
        // &filter[section_id.lecture_id.challenge_id.parent_id][empty]
        const { data } = await ApiService.get(
          `items/courses?fields=whatsapp,id,title,about,short_discription,type_id,instructor_id,en_instructor_id,currency_id,before_discount,after_discount,number_of_hours,hours_description,enhours_description,entitle,enabout,enshort_discription,video_link,start_date,featured_image,attachment,certificate,section_id.id,section_id.title,section_id.duration,section_id.lecture_id.id,section_id.lecture_id.title,section_id.lecture_id.discription,section_id.lecture_id.type,section_id.lecture_id.video_link,section_id.lecture_id.is_mandatory,section_id.lecture_id.duration,section_id.lecture_id.attachment,section_id.lecture_id.is_visible,category_id.category_id,category_id.id,tag_id.id,tag_id.tag_id,language_id.id,section_id.lecture_id.challenge_id.id,section_id.lecture_id.challenge_id.title,section_id.lecture_id.challenge_id.order,section_id.lecture_id.challenge_id.answers.id,section_id.lecture_id.challenge_id.answers.title,section_id.lecture_id.challenge_id.answers.order,section_id.lecture_id.challenge_id.answers.is_answer,section_id.lecture_id.challenge_id.answers.why_is_answer,section_id.lecture_id.challenge_id.lecture_id&filter[type_id][neq]=2`
        );
        this.items = data.data;
        console.log(this.items);
        this.loadingSection = false;
      } catch (error) {
        console.log(error);
      }
    },
    async loadData() {
      try {
        this.loading = true;
        // doce to load data ...
        this.getCourses();
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    dialogOpen(item, type, id) {
      this.record = item;
      this.id = id;
      if (item) {
        this.formTitle = `تعديل ${type}`;
      } else {
        this.formTitle = `إضافة ${type}`;
      }
      if (type == "دورة") {
        this.openDialog0 = true;
      } else if (type == "section") {
        this.openDialog = true;
      } else if (type == "lecture") {
        this.openDialog1 = true;
      } else if (type == "challenge") {
        this.openDialog2 = true;
      }
    },
    formSubmitted(msg) {
      this.openDialog = false;
      this.openDialog1 = false;
      // this.openDialog2 = false;
      this.openDialog0 = false;
      this.snackbarMsg = msg;
      this.snackbar = true;
      this.loadData();
    },
    deleteItem(record) {
      this.record = record;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      try {
        this.loading = true;
        const { data } = await ApiService.delete(
          `items/courses/${this.record.id}`
        );
        this.loading = false;
        this.closeDelete();
        let msg;
        msg = "deleted successfully";
        // }
        alert(msg);
        this.loadData();
      } catch (error) {
        console.log(error);
      }
    },

    closeDelete() {
      this.dialogDelete = false;
    },
  },
  mounted() {
    this.getCourses();
  },
};
</script>
