<template>
  <v-container fluid>
    <v-card class="pa-7">
      <!-- <v-card-title>
        course Page
      </v-card-title> -->
      <v-card-text>
        <contents />
      </v-card-text>
    </v-card>

    <!-- snackbar -->
    <!-- <v-snackbar
      v-model="snackbar"
      absolute
      top
      :multi-line="true"
      :color="snackbarColor"
    >
      {{ snackbarMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar> -->
  </v-container>
</template>

<script>
import ApiService from "@/services/api.service";
import contents from "./comp/Courses";

export default {
  data() {
    return {
      valid: false,
      snackbar: false,
      snackbarMsg: null,
      snackbarColor: "",
    };
  },
  components: {
    contents,
  },
  methods: {},

  mounted() {},
};
</script>

<style></style>
