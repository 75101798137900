<template>
  <v-form ref="form" :valid="valid">
    <v-row>
      <v-col md="4">
        <v-text-field
          placeholder="السؤال"
          label="السؤال"
          v-model="challengeForm.title"
          :rules="[(v) => !!v || 'title is required']"
        />
      </v-col>
      <v-col md="4">
        <v-text-field
          placeholder="question"
          label="question"
          v-model="challengeForm.entitle"
          :rules="[(v) => !!v || 'title is required']"
        />
      </v-col>
      <v-col md="4">
        <v-text-field
          placeholder="الترتيب"
          label="الترتيب"
          v-model="challengeForm.order"
          :rules="[(v) => !!v || 'order is required']"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="10"> </v-col>
      <v-col cols="12" md="2">
        <v-btn color="success" @click="submit">
          Submit
        </v-btn>
      </v-col>
    </v-row>
    <v-overlay :value="submitted">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-form>
</template>

<script>
import ApiService from "@/services/api.service";
// import { mapActions } from "vuex";

export default {
  props: ["formTitle", "record", "dialog", "lecture_id"],
  data() {
    return {
      loadingTypes: false,
      snackbar: false,
      valid: false,
      submitted: false,

      loading: false,
      error: "",
      challengeForm: {
        id: null,
        lecture_id: null,
        title: null,
        entitle: null,
        order: null,
      },
      editorOption: {
        // some quill options
      },
      textRules: [(v) => !!v || "field is required"],

      // =================== for updating users ===================
      initialPayload: {},
    };
  },
  methods: {
    async submit() {
      try {
        if (this.$refs.form.validate()) {
          this.submitted = true;
          let challengeReq;
          this.challengeForm.lecture_id = this.lecture_id;
          console.log(this.challengeForm);
          if (this.record) {
            challengeReq = await ApiService.update(
              "items/challenges",
              this.challengeForm.id,
              this.challengeForm
            );
          } else {
            challengeReq = await ApiService.post(
              "items/challenges",
              this.challengeForm
            );
            this.challengeForm.id = challengeReq.data.data.id;
          }
          this.submitted = false;

          const msg = this.record
            ? "record updated successfully"
            : "record inserted successfully";
          this.$emit("formSubmitted", challengeReq.data.data.id, msg);

          // this.$emit("formSubmitted", msg);
        } else {
          this.snackbar = true;
          this.error = "please fill all required fields";
        }
      } catch (error) {
        if (error.message !== "") {
          this.error = error;
        } else {
          this.error = "sorry, something went wrong";
        }
        this.snackbar = true;
        console.log(error);
        this.submitted = false;
      }
    },
    close() {
      this.$emit("closeDialog");
    },
  },

  async mounted() {
    if (this.record) {
      Object.keys(this.challengeForm).forEach((element) => {
        if (this.record[element]) {
          if (typeof this.record[element] == "object") {
            this.challengeForm[element] = this.record[element].id;
          } else {
            this.challengeForm[element] = this.record[element];
          }
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>
